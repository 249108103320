<template>
    <modal
        class="intro-modal"
        :adaptive="true"
        :click-to-close="false"
        name="intro-modal"
        :height="isMobileScreen ? '100%' : '615px'"
        :width="isMobileScreen ? '100%' : '1000px'"
        :pivot-y="isMobileScreen ? 0 : 0.5"
    >
        <div class="content">
            <h1 class="font-italic">
                Welcome to Memo’d
            </h1>
            <p>We’re so glad you’re joining our knowledge-sharing community.<br class="d-none d-md-block"> We hope you’ll find (and share!) some inspiration.</p>
            <button class="btn btn-primary" @click="showIntro">
                Let’s get started
            </button>
        </div>
    </modal>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "IntroModal",
    computed: {
        ...mapState({
            isMobileScreen: state => state.Application.isMobileScreen
        })
    },
    methods: {
        showIntro() {
            this.$emit("show-intro");
            this.$modal.hide("intro-modal");
        }
    }
}
</script>

<style lang="scss" scoped>
.intro-modal {
    /deep/ .vm--modal{
        background: rgba(58, 58, 60, 0.8);
        border-radius: 10px;
        box-shadow: none;

        .content {
            padding: 25px 20px;
            padding-bottom: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;

            h1 {
                color: white;
                font-size: 54px;
                font-weight: 600;
                font-family: 'Zilla Slab', serif;
                margin-bottom: 20px;

                @media(max-width: $md) {
                    font-size: 34px;
                }
            }

            p {
                font-weight: 17px;
                margin-bottom: 30px;

                @media(max-width: $md) {
                    line-height: 28px;
                }
            }
        }

    }
}
</style>
